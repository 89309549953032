import dynamic from 'next/dynamic';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import logger from 'utilities/logger';
import { capitalizeFirstLetter } from 'utilities/string';

export interface ModuleFeatureProps {
	contentModule: Content.ContentModule | Content.GridModule;
}

export const ModuleFeature: React.FC<ModuleFeatureProps> = ({ contentModule }) => {
	const production = process.env.NODE_ENV === 'production';
	const { documentType } = contentModule;

	const moduleName = capitalizeFirstLetter(documentType?.split('DataCopy')[0]);
	const moduleFeatureName = `${moduleName}Feature`;

	const DynamicModule = dynamic<Content.ContentModule>(() =>
		import(`../${moduleName}/${moduleFeatureName}`).then((module) => module[moduleFeatureName]),
	);

	const message = `Component [${documentType}] is missing a React Component. Add missing export '${moduleName}' from '/features`;

	if (!DynamicModule && !production) {
		return <div>{message}</div>;
	}

	if (!DynamicModule && production) {
		logger.error(message);
		return null;
	}

	const errorHandler = (error: Error, info: { componentStack: string }) => {
		logger.error(`Error with component [${documentType}]: ${error}. ${info.componentStack}`);
	};

	return (
		<ErrorBoundary FallbackComponent={() => null} onError={errorHandler}>
			<DynamicModule {...contentModule} />
		</ErrorBoundary>
	);
};
