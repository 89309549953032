import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { Login, Logout } from 'components/3-organisms/Authentication';
import { Header } from 'designsystem/components/4-habitats/Header';
import { LogoFeature } from 'features/Modules/Logo';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { signoutPage } from 'pages/api/auth/[...nextauth]';

export interface HeaderFeatureProps {
	cprNumber?: string;
}

export const HeaderFeature: React.FC<HeaderFeatureProps> = ({ cprNumber }) => {
	const dictionary = useDictionaryContext();
	const router = useRouter();

	const { data: session, status } = useSession();
	const isAuthenticated = status === 'authenticated' && !session?.error;

	const cprFirstPart = cprNumber && cprNumber?.slice(0, 6);
	const cprSecondPart = cprNumber && cprNumber?.slice(6, cprNumber?.length);

	const cprWithDash = [cprFirstPart, cprSecondPart].join('-');

	return (
		<Header
			logo={<LogoFeature />}
			darkLogo={<LogoFeature dark />}
			enforcedFullLogo={<LogoFeature dark enforceFullLogo />}
			goToMainText={dictionary.getAction('GoToContent')}
			authentication={
				isAuthenticated ? (
					<div className="u-grid u-grid--center">
						{/* <p className="u-grid__cell">{cprNumber ? cprWithDash : session?.user?.legalname || session?.user?.name}</p> */}
						{cprNumber && <p className="u-grid__cell">{cprNumber ? cprWithDash : session?.user?.legalname || session?.user?.name}</p>}
						<Logout logoutText="Log ud" callBack={() => router.push(signoutPage)} />{' '}
					</div>
				) : (
					<Login loginText="Log ind" callBack={() => signIn('keycloak')} />
				)
			}
		/>
	);
};
