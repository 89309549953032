import { usePageContext } from 'application/contexts/PageContext';
import { Footer, FooterSection } from 'designsystem/components/4-habitats/Footer';
import { ModuleFeature } from 'features/Modules/DynamicModule';
import CrownLogo from 'ui/icons/logos/logo-crown.svg';

export const FooterFeature = () => {
	const pageContext = usePageContext();
	const { footer } = pageContext ?? {};

	return (
		<Footer heading={footer?.heading} logoarea={<CrownLogo />}>
			{footer.content
				? footer.content?.map((footerItem, index) => {
						return (
							<FooterSection key={index}>
								<ModuleFeature contentModule={footerItem} />
							</FooterSection>
						);
				  })
				: null}
		</Footer>
	);
};
