import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { Logo } from 'designsystem/components/2-molecules/Logo';
import GaeldstDarkLogo from 'icons/logos/logo-gaeldst-dark.svg';
import GaeldstLogo from 'icons/logos/logo-gaeldst.svg';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { useBoundStore } from 'store';

export interface LogoFeatureProps {
	dark?: boolean;
	enforceFullLogo?: boolean;
}

export const LogoFeature = ({ dark = false, enforceFullLogo = false }: LogoFeatureProps) => {
	const [themeId, setThemeId] = useState<Content.Theme>(null);
	const dictionary = useDictionaryContext();
	const theme = useBoundStore((state) => state.theme);

	useEffect(() => {
		const themeName = theme?.split('-')[1].toUpperCase() as Content.Theme;
		setThemeId(themeName);
	}, [theme]);

	return (
		<NextLink href={'/'} passHref prefetch={false} legacyBehavior>
			<Logo
				heading={dictionary.getExplainer(themeId)}
				linkText={dictionary.getAction('GoToHomePage')}
				logo={dark ? <GaeldstDarkLogo /> : <GaeldstLogo />}
				enforceFullLogo={enforceFullLogo}
			/>
		</NextLink>
	);
};
